:root {
    /*Margins & Paddings*/
    --padding: 1em;
    --margin: 1em;
    --vertical-padding: 1em; /* must NOT be a percentage value */
    --vertical-margin: 1em;  /* must NOT be a percentage value */
     /*Breakpoints*/
    --breakpoint-m: 1100px;
    --breakpoint-s: 800px;
    --breakpoint-xs: 550px;
    --breakpoint-menu: 1100px;
     /*General*/
    --container-max-width: 1100px;
    --fixed-header-height: 80px;
    --base-font-family: Hind, sans-serif;
    --alternative-font-family: var(--base-font-family);

    --header-background: rgba(68, 77, 139, 1.0);
    --body-background: #FFFFFF;
    --body-font-color: #000000;
    
    --nav-font-family: Roboto, sans-serif;
    --nav-font-size: 0.9rem;
    --nav-font-weight: 300;
    --nav-font-color: #CCC;
    --nav-font-color-active: #FFF;
    --nav-border-bottom: 0px solid;
    
    --nav-dropdown-background: rgba(68, 77, 139, 1.0); 
    --nav-mobile-background: rgba(68, 77, 139, 1.0);
    
    --pagecontent-background: rgba(255,255,255,1.0);
    --pagecontent-margin-top: 82vh;
     
    --h-font-family: Roboto, sans-serif;
    --h-font-size: 3.5rem;
    --h-font-weight: 100;
    --h-font-color: #444d8b;
    
    --p-font-family: 'Roboto Condensed', sans-serif;
    --p-font-weight: 300;
    --p-font-color: #000000;
    --p-font-size: 1.05rem;

    --footer-background: rgba(64, 168, 224, 0.9);
    --footer-font-color: #FFFFFF;
   
    --map-marker-color: 000; /* Färgen på kart-ikonen - Ska vara UTAN # innan färgkoden */
    
}

@import "/fonts/Roboto/all.css"; /* 100, 100i, 300, 300i, 400, 400i, 500, 500i, 700, 700i, 900, 900i */
@import "/fonts/Roboto Condensed/all.css"; /* 300, 300i, 400, 400i, 700, 700i */
@import "/css/normalize.css";
    @import "/cssmodules/leaflet2.css";
    @import "/css/basic_styling.css";
@import "/css/flex_grid.v1.1.css";
@import "/css/slick.css";
@import "/css/slick-theme.css";
@import "/css/menu.v1.2.css";
@import "/css/magnific-popup.css";
@import "/css/pikaday.css";
/*@import "/css/animate.css";*/



/*----------------------------------- General --------------------------------------*/

img {
    border: 0;
    max-width: 100%;
    height: auto;
}

.flex > * > img {
    flex: 0 0 auto;
}

/* cross-browser fix, obs! gäller endast för bilder i div utan klass*/
.flex > * > img:not([class]) {
    width: 100%;
}

body {
    color: var(--body-font-color) ;
    background: var(--body-background);
    font-family: var(--p-font-family);
    /* font-weight's att välja mellan är 100,300,400,500,700,900 */
}

header {
    background: var(--header-background);
    z-index: 100;
    position: fixed;
    top: 0;
    width: 100%;
    height: 66px;
}

.hemisdaochdesignav  {
    background: var(--footer-background);
    padding: 8px 20px 8px 8px;
}

.hemisdaochdesignav p, .hemisdaochdesignav a {
    font-family: var(--p-font-family);
    color: var(--footer-font-color);
    font-size: 0.8rem;
    padding:0px;
    margin:0px;
}

.kontaktbanner {
    background: var(--footer-background);
    color: var(--footer-font-color);
    padding-top: 0px;
    padding-bottom: 10px;
}

/*----------------------------------- Logotype --------------------------------------*/

.logo a {
    padding: 0px 0px 0px 25px;
    margin:0px;
}   


.logo img {
    display: block;
    height: 68px;
    max-height: 68px;
    width: auto;
}


/* ---------------------------------- Rubrikerna h1 h2 h3 h4 h5 h6 --------------------------- */

.Galleri h1 {
    font-family: var(--h-font-family);
    font-size: var(--h-font-size);
    color: var(--h-font-color);
    font-weight: var(--h-font-weight);
    text-align: center;
}

h1 {
    font-family: var(--h-font-family);
    font-size: var(--h-font-size);
    color: var(--h-font-color);
    font-weight: var(--h-font-weight);
}

.produkt h2 {
    font-family: var(--h-font-family);
    font-size: 1.3rem;
    color: #FFF;
    font-weight: var(--h-font-weight);
    text-align:center;
    margin-top:0px;
    padding-top:0px;
}

h2 {
    font-family: var(--h-font-family);
    font-size: calc( var(--h-font-size)-0.4rem );
    color: var(--h-font-color);
    font-weight: var(--h-font-weight);
}

h3 {
    font-family: var(--h-font-family);
    font-size: calc( var(--h-font-size)-0.6rem );
    color: var(--h-font-color);
    font-weight: var(--h-font-weight);
}


h4 {
    font-family: var(--h-font-family);
    font-size: calc( var(--h-font-size)-0.8rem );
    color: var(--h-font-color);
    font-weight: var(--h-font-weight);
}


.kontakttextunder h4 {
    font-family: var(--h-font-family);
    font-size: 1.55rem;
    color: #FFF;
    font-weight: var(--h-font-weight);
    margin-bottom:5px;
}


h5 {
    font-family: var(--h-font-family);
    font-size: calc( var(--h-font-size)-0.8rem );
    color: var(--h-font-color);
    font-weight: var(--h-font-weight);
}

h6 {
    font-family: var(--h-font-family);
    font-size: calc( var(--h-font-size)-0.8rem );
    color: var(--h-font-color);
    font-weight: var(--h-font-weight);
}

p  {
    font-family: var(--p-font-family);
    font-size: var(--p-font-size);
    color: var(--p-font-color);
    font-weight: var(--p-font-weight);
    line-height: 150%;
}

.produkt p  {
    font-family: var(--p-font-family);
    font-size: 1.0rem;
    color: #FFF;
    font-weight: var(--p-font-weight);
    line-height: 150%;
}

.kontakttextunder p {
    margin-top:5px;
}


.pagecontent ul {
    font-family: var(--p-font-family);
    font-size: var(--p-font-size);
    font-weight: var(--p-font-weight);
    color: var(--p-font-color);
    line-height: 150%;
    padding-left:1vw;
}

/*-------------------------------------------------------------- Produkterna / Produkt ------------------------------------------------------------------------*/

.produktwraper {
    padding-bottom:2vh;
}

.produkt {
    background: #5fb5e4;
    border-radius:2px;
    
}

.produkt img {
    border: 0px;
}

.produktimagelist img {
    max-height:65px;
    width:auto;
}




/*-------------------------------------------------------------- Bildspel ------------------------------------------------------------------------*/

.slick-initialized .slick-slide{
    display: flex;
}

.slick-slider {
    margin-top: 0px;
    position: fixed;
    width:100%; 
    top:0;
    z-index: -10; 
}

.slick-slide {
    height: 100vh;
    width:100%;
    background-size: cover;
    background-position: top center ;
}

.slideshow_titel {
    font-family: 'Roboto',arial,san-serif;
    background: rgba(51, 51, 51, 0.25);
    font-weight: 100;
    font-size: 2.8em;
    position: relative;
    text-transform: uppercase;
    color: #fff;
    letter-spacing: 3px;
    text-align: center;
    margin-top: 50vh;
    margin-bottom: 0;
    z-index: -8;
    width: 100%;
    height: 60px;
    border-style: solid;
    border-width: 2px;
    border-left: 0;
    border-right: 0;
    border-color: #fff;
}

/*-------------------------------------------------------------- Topimage / Toppbild ------------------------------------------------------------------------*/

.topimage {
    margin-top: 0px;
    position: fixed;
    width:100%; 
    height:100vh;
    top:0;
    z-index: -10; 
    background-size: cover;
    background-position: top center;
}

/*-------------------------------------------------------------- Facebookflöde ------------------------------------------------------------------------*/

#slideout {
    background: #EEE;
    box-shadow: 0 0 5px rgba(0,0,0,.3);
    color: #333;
    position: fixed;
    top: 50%;
    z-index: 10000;
    transition: transform .3s;
    transform: translateX(-100%) translateY(-50%);
}

#slideout.on {
  transform: translateX(-0%) translateY(-50%);
}

/*--- SlideOut-Knappen ----*/
.show-slide {
    font-family: var(--h-font-family);
    position: absolute;
    top: 50%;
    right: 0px;
    z-index: 10000;
    transform: rotate(90deg) translateY(-280%) translateX(-15%);
    background: #29487d;
    color:#FFFFFF;
    border:none;
    border-radius:0px;
    margin-right: 29px;
    padding: 10px 30px;
    border-radius: 5px 5px 0px 0px;
}

.slideoutwrapper {
    padding:2em;
    max-height:100vh;
    max-width: 520px;
    overflow: auto;
}

.slideoutwrapper h3 {
    font-family: var(--h-font-family);
    font-size: calc( var(--h-font-size)-0.6rem );
    color: var(--h-font-color);
    font-weight: var(--h-font-weight);
    margin-bottom:0px;
}

.slideoutwrapper p {
    margin-top:10px;
    margin-bottom:10px;
}


.news-block {
    top: 20em;
}

.news-block .buttonfbreadmore {
    padding: 8px 12px;
    color: #FFF;
    background: #29487d;
    text-decoration: none;
    border-radius: 5px;
    display: inline-block;
    margin: 0px 0px 20px 0px;
    text-align: center;
    border: 1px solid;
}

.news-block img {
    display: block;
    margin-top: 4vh;
}


/*-------------------------------------------------------------- Pagecontent / Innehåll sida och startsida ------------------------------------------------------------------------*/

.pagecontent {
    margin-top: var(--pagecontent-margin-top);
    position: relative;
    z-index: 15;
    background: var(--pagecontent-background);
}

.contentwrappercontact {
    padding-top:2vh;
    padding-bottom:2vh;
    display:flex;
    flex-wrap: wrap;
    align-items: center; /* "v-align" flex-start, center flex-end */
    justify-content: space-between; /* "h-align" flex-start, center, flex-end, space-between */
}

.contentwrapper {
    display:flex;
    flex-wrap: wrap;
    align-items: flex-start; /* "v-align" flex-start, center flex-end */
    justify-content: space-between; /* "h-align" flex-start, center, flex-end, space-between */
}

.pagetitle {
    flex-basis:100%;  /*flex:1;*/
    margin-bottom:0vh;
    padding-bottom:0vh;
}

.pagetitle h1 {
    margin-top:1.5vh;
    padding-top:0vh;
    margin-bottom:0vh;
    padding-bottom:0vh;
}

.bodycol {
    flex-basis:48%;  /*flex:1;*/
    margin-top:0vh;
    padding-top:0vh;
}


.bodycolfour {
    flex-basis:33%;  /*flex:1;*/
    margin-top:0vh;
    padding-top:0vh;
    padding-left:2vw;
    padding-right:2vw;
}

.bodycoleight {
    flex-basis:67%;  /*flex:1;*/
    margin-top:0vh;
    padding-top:0vh;
    padding-left:2vw;
    padding-right:2vw;
    padding-bottom:2vh;
}

.bodycol img {
    margin-top:20px;
}

.pdfloop  {
    color: var(--p-font-color);
    line-height: 150%;
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 1.1em;
    font-weight: 300;
    line-height: 150%;
    margin-top:3vh;
}

.pdfloop a {
    color: var(--p-font-color);
}

.pdfloop svg {
    fill: var(--p-font-color);
    height: 22px;
    width: 30px;
}

.margin>* {
    margin-top: 0px;
}

.kontaktlankboxbild {
    min-height: 250px;
    max-height: 250px;
    position: relative;
    background-size: cover;
    background-position: center center;
}

a.kontaktlankboxbild{
    display:block;
}

/*------------------------- Logobanner ---------------------------*/

.logobanner {
    padding-top: 6vh;
    padding-bottom: 3vh;
}

/*---------- Gallery galleri galleries --------------*/

.gallerybackground {
    background: var(--pagecontent-background);
}

.imagelist_link {
    min-height: 500px;
    width: 100%;
    position: relative;
    background-size: cover;
    background-position: center center;
}

a.imagelist_link{
    display:block;
}

/*----------------------------------- Länkarna --------------------------------------*/

a {
    text-decoration: none;
}

a:active, a:hover {
    text-decoration: underline;
}

/*----------------------------------- Meny / Menu --------------------------------------*/

/*-- visa bara det adra språket --*/
.localeswitcher.active {
    display:none;
} 

nav li{
    background: none;
}

nav {
    font-size: var(--nav-font-size);
    color: var(--nav-font-color);
    font-family: var(--nav-font-family);
    font-weight: var(--nav-font-weight);
    float:right;
    letter-spacing: 1px;
    text-transform: uppercase;
}

.menu li a,
.menu .dropdown ul
{
    text-decoration: none;
}

.menu li {
    color: var(--nav-font-color);
    border-bottom: var(--nav-border-bottom) rgba(255, 255, 255, 0.0);
}
.menu>li {
    margin: 0 1em;
    list-style: none;
    margin-bottom: 10px;
    padding: 15px 10px 8px;
}

.menu .active,
.menu .current,
.menu .activeparent,
.menu li:hover
{
    color:var(--nav-font-color-active);
    text-decoration: none;
    border-bottom: var(--nav-border-bottom) var(--nav-font-color-active);
}


/* ------------------------------------------------ DropDownMenyn / Drop Down Menu -------------------------------------------- */

 .menu .dropdown ul{
    top: calc(100% + 0px);
    left: 26px;
    background: var(--nav-mobile-background);
    border-bottom:none;
    border-width: 0px;
    border-top: solid 20px rgba(0,0,0,0.0)
}

.menu .dropdown li {
    white-space: nowrap;
    background: none;
    width:100%;
}

.menu .dropdown li a {
    min-width: 100px;
    padding: 12px 2px 12px 2px;
    margin-left: 15px;
    margin-right: 15px;
}

/*------------------------------------- Pageboxes Sidboxar ---------------*/

.pageboxes {
    background: var(--pagecontent-background);
    padding-top:1vh;
    padding-bottom:1vh;
}

.pageboxwrapper {
/*    border: solid 1px #000000;*/
    position: relative;
    overflow: hidden; 
    cursor: pointer; 
    margin:0; 
    width:100%;
    height: 365px;
    position: relative;
    display:block;
}

.pageboxbackground{
    position: absolute; 
    transform-origin: 50% 50%;
    background-size: cover;
    background-position: top center;
    transform: scale(1.05);
    transition: transform 0.5s ease-out 0s;
    top:0;
    right:0;
    bottom:0;
    left:0;
    display:block;
}

.pageboxwrapper:hover .pageboxbackground {
    transform: scale(1.00);
}

.pageboxtitle {
    font-family: "Roboto Condensed", sans-serif;
    font-size: 1.8rem;
    font-weight: 200;
    text-align: center;
    color: #000000;
    text-shadow: 1px 1px rgba(0,0,0,0.6);
    position: absolute; 
    left: -2px;
    right: -2px;
    top: 40%;
    transition: transform 0.5s ease-out;
    max-width: none;
    will-change: transform;
}

.pageboxwrapper:hover .pageboxtitle {
    transform: scale(1.1);
}

.pageboxcover {
    transform: scale(1.0);
    background: rgba(255,255,255,.8);
    position: absolute; 
    left: -2px;
    right: -2px;
    top: +2px;
    bottom: +2px;
    opacity: 0.8;
    transition: opacity 0.5s ease-out;
    max-width: none;
    will-change: transform;
}

.pageboxwrapper:hover .pageboxcover {
    opacity: 0.0;
}

/*------------------------------------- Moduler / Modul / Länkboxar ---------------*/

.moduleboxes {
    background: var(--pagecontent-background);
    padding-top:1vh;
    padding-bottom:1vh;
}

.moduleboxwrapper {
    border-radius:50%;
    position: relative;
    overflow: hidden; 
    cursor: pointer; 
    margin:0; 
    width:100%;
}

.moduleboximage {
    height: 465px;
    position: relative;
    display:block;
}

.moduleboximage > div{
    color: #666666;
    background-color: rgba(255,255,255, 0.9);
    font-family:'Roboto', san-serif;
    position:absolute; 
    line-height: 1.5em;
    text-align: center;
    max-width: none;
    will-change: transform;
}

.coverimage{
    transform-origin: 50% 50%;
    background-size: cover;
    background-position: center;
    transform: scale(1.0);
    transition: transform 0.5s ease-out 0s;
    top:0;
    right:0;
    bottom:0;
    left:0;
}

.moduleboximage:hover .coverimage {
    transform: scale(1.05);
}

.boxtitle1 {
    left: -2px;
    right: -2px;
    background: rgba(255,255,255, 0.8);
    font-weight: 500;
    font-size: 1.3em;
    letter-spacing: 1px;
    text-transform: uppercase;
    line-height: 1.5em;
    color: #666666;
    transition: transform 0.3s ease-out;
}

.moduleboxwrapper:hover .boxtitle1 {
    transform: translateY(100%);
}

.box.boxtitle2 {
    left: -2px;
    right: -2px;
    color: #666666;
    bottom:0;
    font-weight: 300;
    font-size: 1.5rem;
    transition: transform 0.3s ease-out;
}

.moduleboxwrapper:hover .boxtitle2 {
    transform: translateY(-100%);
}

.boxtitle3  {
    font-weight: 500;
    font-size: 1.2em;
    letter-spacing: 1px;
    text-transform: uppercase;
    line-height: 1.5em;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0.0;
    transition: opacity 0.3s ease-out 0s;
    display: inline-block;
    border: 1px solid;
    padding:10px 20px 10px 20px;
}

.moduleboxwrapper:hover .boxtitle3 {
    opacity: 1.0;
}

/* -------------------------------------- Listing ----------------------------------------- */

.listing {
    padding-left: 20px;
    padding-right: 20px;
    max-width: 720px;
}

.listing h2 {
margin-top: 45px;
}

.readmore svg.fa-icon, .goback svg.fa-icon {
    width: 18px;
    height: 18px;
    fill: #000000;
}

/* --------------------------------------------------------- Kontaktformulär / Kontaktbanner ----------------------------------------- */

input[type=text], input[type=email] {
     color: #ffffff;
}

.boltforms-row label {
    font-size:0px;
    font-weight: 400;
    margin-bottom: 0;
}

.boltforms-row input {
    width: 100%;
    color: #ffffff;
    border: none;
    background: rgba(255,255,255,0.1);
}

.boltforms-row textarea {
    background: rgba(255,255,255,0.1);
    width: 100%;
    color: #fff;
    height: 15vh;
    border: none;
}

.boltforms-row button {
    display: block;
    width: 100%;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.1);
    margin: 0;
    border: none;
    border-radius: 2px;
    height: auto;
    padding: 10px 20px;
    font-weight: 400;
    font-size: 1em;
    line-height: 1.1em;
}

::-webkit-input-placeholder {
   color: #FFFFFF;
}

:-moz-placeholder { /* Firefox 18- */
   color: #FFFFFF;  
}

::-moz-placeholder {  /* Firefox 19+ */
   color: #FFFFFF;  
}

:-ms-input-placeholder {  
   color: #FFFFFF;  
}

/*--------------------- Kontaktbanner-texten ----------------------*/

.socialmedia {
    font-size:1.3rem;
    letter-spacing: 5px;
}

.kontaktbanner a {
    color: #ffffff;
}

.kontaktbanner h5 {
    color: #ffffff;
    font-size: 1.5rem;
    margin-top: 0px;
    margin-bottom: 2vh;
}

.kontaktbanner p {
    font-size: var(--p-font-size);
    color: #ffffff;
    margin-bottom: 0px;
    line-height: 150%;
}

.kontaktbanner .boltforms-row {
    float: left;
    width: 33.3%;
    padding-right: 10px;
}

.kontaktbanner .boltforms-row:nth-child(n+4) {
    width: 100%;
}

button[type=submit], input[type=submit] {
    background: rgba(255,255,255,0.1);
    color: #FFFFFF;
    transition: background 0.8s;
}

button[type=submit]:hover, input[type=submit]:hover {
    background: rgba(255,255,255,0.2);
    color: #FFFFFF;
}

/*------------------Give map a height and it's images no max-width----------*/

.markerLabels{
    overflow:visible !important;
}

.fa-map-marker{
    background: url(/extensions/local/intendit/intenditExt/web/black/map-marker.svg);
    background-size: cover;
    padding: 10px;
    background-position: center;
}

/*------------------Give map a height and it's images no max-width----------*/

    .map-canvas {
    height: 38vh;
}

.map-canvas img {
    max-width: none;
}

.markerLabels{
    overflow:visible !important;
}

.fa-map-marker{
    background: url('data:image/svg+xml;utf8,%3Csvg%20fill%3D%22%23var(--map-marker-color)%22%20width%3D%221792%22%20height%3D%221792%22%20viewBox%3D%220%200%201792%201792%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M1152%20640q0-106-75-181t-181-75-181%2075-75%20181%2075%20181%20181%2075%20181-75%2075-181zm256%200q0%20109-33%20179l-364%20774q-16%2033-47.5%2052t-67.5%2019-67.5-19-46.5-52l-365-774q-33-70-33-179%200-212%20150-362t362-150%20362%20150%20150%20362z%22%2F%3E%3C%2Fsvg%3E');
    background-size: cover;
    padding: 10px;
    background-position: center;
}

/*------------------------------------------------------------------------------------------------ 
MEDIA QUERIES ----------------------------------------------------------------------------------*/

/*Allt under M-nivå*/
@media (max-width: var(--breakpoint-m)) {
    
    #menu-icon:before, #menu-icon:after, #menu-icon span {
        color:#FFF;
    border: 2px solid;
    width: 30px;
    margin-bottom: 5px;
    display: block;
    transition: all .1s;
    content: "";
}
    
    .menu.show {
        background: var(--nav-mobile-background);
    }
 .menu .dropdown ul{
    background: none;
    }    

        .bodycol, .bodycolfour, .bodycoleight {
        flex-basis:100%;  /*flex:1;*/
        margin-left:2vw;        
        margin-right:2vw;
    }
    
    .pagetitle {
        margin-left:2vw;        
        margin-right:2vw;
    }
    
} /* SLUT - Allt under M-nivå*/ 

/*Allt under S-nivå*/
@media (max-width: var(--breakpoint-s)) {

    .kontaktbanner .boltforms-row {
        float: left;
        width: 100%;
        padding-right: 10px;
    }

    
    .Galleri h1 {
    font-family: var(--h-font-family);
    font-size: calc( var(--h-font-size)-1.8rem );
    color: var(--h-font-color);
    font-weight: 300;
    text-align: center;
}

h1 {
    font-family: var(--h-font-family);
    font-size: calc( var(--h-font-size)-1.8rem );
    color: var(--h-font-color);
    font-weight: 300;
}

h2 {
    font-family: var(--h-font-family);
    font-size: calc( var(--h-font-size)-2.0rem );
    color: var(--h-font-color);
    font-weight: 300;
}

h3 {
    font-family: var(--h-font-family);
    font-size: calc( var(--h-font-size)-2.2rem );
    color: var(--h-font-color);
    font-weight: 300;
}


h4 {
    font-family: var(--h-font-family);
    font-size: calc( var(--h-font-size)-2.2rem );
    color: var(--h-font-color);
    font-weight: 300;
}


.kontakttextunder h4 {
    font-family: var(--h-font-family);
    font-size: 1.55rem;
    color: #FFF;
    font-weight: var(--h-font-weight);
    margin-bottom:0px;
}


h5 {
    font-family: var(--h-font-family);
    font-size: calc( var(--h-font-size)-2.2rem );
    color: var(--h-font-color);
    font-weight: 300;
}

h6 {
    font-family: var(--h-font-family);
    font-size: calc( var(--h-font-size)-2.2rem );
    color: var(--h-font-color);
    font-weight: 300;
}
  
}  

/*Allt under XS-nivå*/
@media (max-width: var(--breakpoint-xs)) {
    
    .slideoutwrapper {
    padding:2em;
    max-height:100vh;
    max-width: 90vw;
    overflow: auto;
}
    
}

@media screen and (orientation: landscape) and (max-width: 800px) {


}

@media screen and (orientation: landscape) and (max-width: 800px) {

    
}

/*--------------------------Make FB widget responsive------------------------*/
    .fb_iframe_widget,
    .fb_iframe_widget span,
    .fb_iframe_widget iframe[style],
    .twitter-timeline {
        width: 100% !important;
    }
